/**
 * Titel: RESPONSIVE
 * Beskrivelse: indeholder efterhånden primært logik til omsættelse af tabeller til responsiv opførsel (lister), herunder studiediagrammerne. Derudover er der en lille smule logik til det nye design
 * Status: er kun i nogen grad omskrevet fra 2011/2015-udgaven
 */

AU.responsive = {
    state: null,
    stateHistory: [],
    modes: {
        PHONE: {
            ID: 0,
            NAME: 'PHONE',
            MAX: 640
        },
        TABLET: {
            ID: 1,
            NAME: 'TABLET',
            MAX: 1024
        },
        DESKTOP: {
            ID: 2,
            NAME: 'DESKTOP'
        }
    },
    detectDevice: function () {

        var state = null;
        var modes = AU.responsive.modes;
        if (Foundation.MediaQuery.current === 'small') {
            state = modes.PHONE;
        }
        else if (Foundation.MediaQuery.current === 'medium') {
            state = modes.TABLET;
        }
        else {
            state = modes.DESKTOP;
        }

        if (state !== AU.responsive.state) {
            AU.responsive.state = state;
            AU.responsive.stateHistory.push(state.NAME);
            console.log(state.NAME);
            AU.responsive.setMarkup();
        }
    },
    markupRenderFunctions: [],
    runMarkupFunctions: function () {
        for (var i = 0; i < this.markupRenderFunctions.length; i++) {
            if (typeof this.markupRenderFunctions[i] === 'function') {
                this.markupRenderFunctions[i]();
            }
        }
    },
    setMarkup: function () {
        var state = AU.responsive.state;

        var utilityLinks = jQuery('.utility-links');
        var headerMenu = jQuery('.header-menu');
        var sectionHeader = jQuery('.section-header .logo');

        var pageNav = jQuery('#au_section_nav_inner_list');
        var titleBar = jQuery('.title-bar');
        var menuTitle = jQuery('.menu-title');
        var leftMenu = jQuery('.left-menu');
        var tablesScroll = jQuery('table.au_responsive_table_scroll');

        if (state.NAME === 'PHONE') {

            headerMenu.append(utilityLinks);
            if (jQuery('body').hasClass('universe')) {
                headerMenu.after(pageNav);
                pageNav.wrap('<div class="large-12 medium-12 small-12 columns" />');
            } else {
                utilityLinks.before(pageNav);
            }
            titleBar.before(menuTitle);
            pageNav.addClass('responsive-pagenav');

            tablesScroll.each(function () {
                var table = jQuery(this);
                table.wrap('<div class="responsive-table-container" />');
            });

        } else {

            var wasPhone = function () {
                var states = AU.responsive.stateHistory.length;
                return states > 1 ? AU.responsive.stateHistory[states - 2] === 'PHONE' : false;
            };

            if (wasPhone()) {
                sectionHeader.append(utilityLinks);
                leftMenu.append(pageNav);
                pageNav.before(menuTitle);

            }

            tablesScroll.each(function () {
                var table = jQuery(this);
                if (table.parent().is('div.responsive-table-container')) {
                    table.unwrap();
                }
            });
        }

        this.runMarkupFunctions();
    }
};

AU.edutables = {
    tables: [],
    scriptLoaded: false,
    loadExternal: function () {
        const dg = new AUDiagramme(AU.pageLang, AU.edutables.tables);
        dg.init();
    },
    init: function () {
        if (this.tables.length > 0) {
            if (!this.scriptLoaded) {
                const componentsUrl = AU.cdn + '/components/umd/diagramme.js';
                jQuery.getScript(componentsUrl, function () {
                    AU.edutables.loadExternal();
                    AU.edutables.scriptLoaded = true;
                });
            } else {
                this.loadExternal();
            }
        }
    }
};

AU.ready.push(function () {
    AU.responsive.detectDevice();
    AU.edutables.init();
});

AU.resize.push(function () {
    AU.responsive.detectDevice();
});
